.SearchDiv{
    display: flex;
    padding: 10px; 
    margin-top: 50px;
}

.search-left{

        margin-top: 20px;
        width: 30%;
        box-shadow: 2px 2px 15px grey;
        padding: 20px;
        margin-right: 20px;
        border-radius: 10px;
        height:800px;
      
}
  
.search-right{
    
        margin-top: 20px;
        width: 70%;
        box-shadow: 2px 2px 15px grey;
        padding: 20px;
        border-radius: 10px;
      
}

  
  /* Media Queries for Mobile */
  @media (max-width: 768px) {
   
  }
  
  /* Media Queries for Tablet */
  @media (min-width: 769px) and (max-width: 1024px) {
  
  
    
  }
  
  @media (max-width: 480px) {
    .SearchDiv{
        display: block;
        padding: 10px; 
        margin-top: 50px;
    }
    
    .search-left{
    
            width: 100%;
            height:750px;
          
    }
      
    .search-right{
        
            width: 100%;
    }
    
  }
  