.component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }
  
  .input-group .form-floating {
    margin-right: 10px;
  }
  
  .input-group .btn {
    padding-top: 14px;
    padding-bottom: 14px;
  }

    
  @media (max-width: 480px) { /* Mobile devices */
    .component-container {
      width: 100%;
      /* padding: 10px; */
    }
    .input-group .form-floating {
      /* margin-right: 0;
      margin-bottom: 10px; */
      width: 65%;
    }
/*   
    .in1{
      width: 40%;
    } */
   
  }

  
  @media (min-width: 480px) and (max-width: 767px) { /* Mobile devices */
    .component-container {
      width: 100%;
      /* padding: 10px; */
    }
    .input-group .form-floating {
      /* margin-right: 0;
      margin-bottom: 10px; */
      width: 72%;
    }
  
    .in1{
      width: 40%;
    }
   
  }
  
  @media (min-width: 768px) and (max-width: 1023px) { /* Tablet devices */
    .component-container {
      width: 100%;
    }
    .input-group .form-floating {
      width: 73%;
    }
  }
  
  @media (min-width: 1200px) { /* Laptop devices and above */
    .component-container {
      width: 100%;
    }
    
     /* .input-group {
      flex-direction: column;
    } */
  
    .input-group .form-floating {

      width: 385px;
    }
 
  }
  