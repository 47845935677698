.agents{
    display: flex;
    flex-direction: column;
}

.agents-row{
    margin-top: 10px;
    padding: 0px 20px;
    padding-right: 120px;
}

@media screen and (max-width: 768px) {
    .agents-row{
        padding-right: 100px;
    }

    .agent-heading{
        padding-right: 50px;
    }
      
    .img-fluid{
        width: 400px;
    }
}

@media screen and (max-width: 480px) {
    .agents-row{
        padding-right: 30px;
    }

    .agent-heading{
        padding-right: 10px;
    }

    .img-fluid{
        width: 400px;
    }
      
}