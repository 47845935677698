.container {
  margin-top: 50px;
  margin-left: 40px;
  display: flex;
}



.profile {

  /* margin-top: 50px; */
  /* margin-left: 300px; */
  padding: 30px;
  padding-top: 50px;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px grey;
  background-color: white;
  width: 35%;
  margin-right: 20px;
  align-items: center;
  height: 550px;
}

.profile-image {
  text-align: center;
}

.change-password {
  background-color: transparent;
  padding: 0px;
  border: none;
  color: rgb(15, 89, 162);
  bottom: 0;
  /* left: 0; */
  bottom: 0;
  right: 0;
  margin-left: 50%;
  text-align: right;
}

.view-property {
  padding: 10px;
}
.user-properties {
  background-color: white;
  padding: 20px;
  padding-top: 50px;
  width: 65%;
  border-radius: 10px;
  box-shadow: 0 0 10px grey;
}

.user-icon{
  border: 1px solid #2e307d;
  border-radius: 100px;
  padding: 10px;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .profile {
    margin-left: 0;
    width: 90%;
  }
}

/* Media Queries for Tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .profile {
    margin-left: 100px;
    width: 80%;
  }

  
}

@media (max-width: 480px) {
  .container {
    display: block;
    margin: 0;

    padding: 20;
  }

  .user-properties {
    width: 100%;
    margin-top: 50px;


  }

  .profile {

    /* margin-top: 50px; */
    /* margin-left: 300px; */
    padding: 25px;
    padding-top: 20px;
    margin-top: 50px;
    font-size: 20px;
    border-radius: 10px;
    font-size: 18px;
    box-shadow: 0 0 10px grey;
    background-color: white;
    width: 100%;
    margin-right: 10px;
    align-items: center;
    height: 550px;
  }
}
