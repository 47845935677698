.main {
     display: flex; 
     padding: 10px ;
}
.filters {
    margin-top: 20px;
    width: 30%;
    box-shadow: 2px 2px 15px grey;
    padding: 20px;
    margin-right: 20px;
    border-radius: 10px;
    height: 750px;
 
}
.filter-input {
    background-color: #eee;
}
.listing {
        margin-top: 20px;
        width: 70%;
        box-shadow: 2px 2px 15px grey;
        padding: 20px;
        border-radius: 10px;
      
}
@media (max-width:480px) {
    .main {
        display: block; 
        padding: 10px ;
   }
   .filters {
    width: 100%;
 
}
.listing {
    width: 100%;
  
}
    
}