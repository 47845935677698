@media only screen and (max-width: 480px) {
  .cardcontainer {
    background-color: green;
  }
  .card {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 480px) {
  .card {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .cardcontainer {
    display: flex;
    flex-direction: row;
  }
  .card {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .cardcontainer {
    
    display: flex;
    flex-direction: row;
  }
  .card {
    margin-left: 480px;
  }
}
@media only screen and (min-width: 1200px) {
  .cardcontainer {
   
    display: flex;
    flex-direction: row;
   
  }
  .card {
    margin-left: 480px;
    width: 40%;
  }
}
