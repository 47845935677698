.property-list {
  background-color: #eee;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px grey;
  
}
.property-list:hover {
  box-shadow: 2px 2px 15px grey;
    
  }
  .title {
      color: #2e307d;
      font-size: 22px;
      font-weight: bold;
      font-family: tahoma;
  }
  
  .view-profile {
    margin-left: 10px;
    background-color: #2e307d;
    color: white;
  }
  .view-detail {
    background-color: #2e307d;
    color: white;
  }
  .view-profile:hover {
    background-color: transparent;
    color: #2e307d;
    border: 1px solid #2e307d;
    transition: 0.8s ease-in-out;
  }
  .view-detail:hover {
    background-color: transparent;
    color: #2e307d;
    transition: 0.8s ease-in-out;
    border: 1px solid #2e307d;
  }


  .icons{
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 20px;
  }

  .sub-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 10px;
  }

  .s1:hover{
    cursor: pointer;
  }

  .sub-icons p{
    margin-left: 13px;
    margin-top: 15px;
  }

  @media(max-width: 768px) {
    .property-list {
      background-color: #eee;
      margin: 0px;
      /* margin-left: 50px;
      margin-right: 50px; */
      margin-bottom: 20px;
      border-radius: 10px;
      box-shadow: 2px 2px 5px grey;
      
    }
    .title {
      color: #2e307d;
      font-size: 18px;
      font-weight: bold;
      font-family: tahoma;
  }
    .description {
      display: none;
    }
    #login_img {
      display: none;
    }
    .like-icon {
      background-color: #2e307d;
    }
    .action {
      background-color: #2e307d;
    }
    .icon-text {
      /* background-color: #2e307d; */
      /* padding-left: 10px;
      padding-right: 70px; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
    }

    .icons{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
      font-size: 20px;
    }
  
    .sub-icons{
      display: flex;
      padding: 0px 1px;
    }
  
    .sub-icons p{
      margin-left: 13px;
      
    }
    .s1{
      cursor: pointer;
    }
    .s2{
      margin-top: -30px
    }

    .s3{
      margin-top: -30px;
    }
  }