/* for mobiles */
@media only screen and (max-width: 480px) {
  .form-start {
    /* background-color: #093d76; */
    width: 100%;
  }
  .add_property {
    padding-right: 35px;
    /* margin-left: -25px; */
    padding-top: 20px;
    margin-top: 20px;
    width: 100%;
    /* background-color: #00b98e; */
  }

  .submit-btn1 {
    position: absolute;
    right: 22%;
  }

  .property_purpose {
    border: 1px solid;
    width: 40%;
    height: 60px;
    background-color: #2e307d;
    color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin-left: 20px;
    color: white;
  }
  .property_purpose1 {
    border: 1px solid;
    width: 40%;
    height: 60px;
    background-color: #00b98e;
    color: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin-left: 20px;
    color: white;
  }

  .spanClass {
    font-size: 18px;
    /* padding-right: 10px; */
    /* padding-left: 10px; */
  }

  .property_subtype {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: auto;
    padding: 10px;
    height: 60px;
    border-radius: 10px;
    text-align: left;
    color: white;
  }
  .property_subtype1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: auto;
    padding: 10px;
    height: 60px;
    border-radius: 10px;
    text-align: left;
    color: white;
  }

  .action_submit_buttons {
    padding: 20px;
    width: 200px;
    border-radius: 30px;
  }

  .panel {
    margin: 0px;
    padding: 0px;
  }

  #propertysize {
    width: 100%;
  }

  .propertybuiltyear {
    background-color: inherit;
    color: inherit;
    margin-left: 30px;
    border: none;
  }

  .btn__container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 20px;
  }

  .property_details {
    border: 3px solid;
    background-color: #2e307d;
    color: white;
    width: 350px;
    height: 60px;
    border-radius: 10px;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 1px;
    padding-top: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  .property_details1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 350px;
    height: 60px;
    border-radius: 10px;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 1px;
    padding-top: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .propertydetailcounter {
    background-color: inherit;
    color: inherit;
    border: none;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 90%;
    height: 60px;
    border-radius: 5px;
    padding-top: 20px;
    text-align: left;
  }

  .numberofbedroomcounter {
    background-color: inherit;
    border: none;
    margin-left: 150px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .options-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .options-right button {
    margin: 0px 10px;
    background-color: #2e307d;
    border: none;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .subtract {
    padding: 0px 10px;
  }
  .add {
    padding: 0px 8px;
  }

  .property-details-container {
    display: none;
    position: fixed;
    top: 100px;
    right: 0;
    width: 40%;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 10;
  }

  .property-image {
    width: 100%;
    height: 100%;
  }

  .property-details {
    padding: 1rem;
  }

  .property-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .property-type {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .property-price {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .property-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .location-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-location {
    font-size: 1.2rem;
  }

  .property-description {
    margin-bottom: 1rem;
  }

  .property-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .property-features li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-map-container {
    height: 300px;
    margin-bottom: 1rem;
    /* Add your custom styling for the map component here */
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .add_property {
    width: 100%;
  }

  .spanClass {
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .property_purpose {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-left: 30px;
  }
  .property_purpose1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-left: 30px;
  }

  .property_subtype {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 22px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }
  .property_subtype1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 22px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 470px;
    height: 60px;
    border-radius: 5px;
    padding-top: 20px;
    text-align: left;
  }

  .numberofbedroomcounter {
    background-color: white;
    color: #00b98e;
    margin-left: 230px;
  }

  .property_details {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 500px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .property_details1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 500px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    color: white;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .propertybuiltyear {
    background-color: inherit;
    color: inherit;
    margin-left: 160px;
    border: none;
  }

  .propertydetailcounter {
    background-color: inherit;
    color: inherit;
    border: none;
  }

  .numberofbedroom {
    border: 1px solid;
    background-color: white;
    color: #2e307d;
    width: 450px;
    height: 60px;
    border-radius: 5px;
    padding-top: 20px;
    text-align: left;
  }

  .numberofbedroomcounter {
    background-color: inherit;
    color: white;
    border: none;
    margin-left: 50px;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 450px;
    height: 60px;
    border-radius: 5px;
    padding-top: 20px;
    text-align: left;
  }

  .numberofbedroomcounter {
    background-color: inherit;
    color: white;
    border: none;
    margin-left: 50px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .options-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .options-right button {
    margin: 0px 10px;
    background-color: #2e307d;
    border: none;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .subtract {
    padding: 0px 10px;
  }
  .add {
    padding: 0px 8px;
  }

  .service_available {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 400px;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: white;
    margin-bottom: 5px;
  }

  .service_available1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 400px;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: white;
    margin-bottom: 5px;
  }

  .numberoffloors {
    background-color: inherit;
    color: inherit;
    border: none;
    margin-left: 70px;
  }

  .action_submit_buttons {
    margin: 0px;
    width: 200px;
    padding: 10px;
  }

  .property-details-container {
    display: none;
    position: fixed;
    top: 100px;
    right: 0;
    width: 40%;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 10;
  }

  .property-image {
    width: 100%;
    height: 100%;
  }

  .property-details {
    padding: 1rem;
  }

  .property-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .property-type {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .property-price {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .property-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .location-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-location {
    font-size: 1.2rem;
  }

  .property-description {
    margin-bottom: 1rem;
  }

  .property-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .property-features li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-map-container {
    height: 300px;
    margin-bottom: 1rem;
  }
}

/* for ipad,tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .spanClass {
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .add_property {
    width: 80%;
  }

  .property_purpose {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 15px;
  }
  .property_purpose1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 40%;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 15px;
  }

  .property_subtype {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 22px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }
  .property_subtype1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 22px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }

  .property_details {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
  .property_details1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    color: white;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .propertybuiltyear {
    background-color: inherit;
    color: white;
    border: none;
    margin-left: 400px;
  }

  .propertydetailcounter {
    background-color: inherit;
    color: inherit;
    border: none;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 450px;
    height: 60px;
    border-radius: 5px;
    padding-top: 20px;
    text-align: left;
  }

  .numberofbedroomcounter {
    background-color: inherit;
    color: white;
    border: none;
    margin-left: 50px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .options-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .options-right button {
    margin: 0px 10px;
    background-color: #2e307d;
    border: none;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .subtract {
    padding: 0px 10px;
  }
  .add {
    padding: 0px 8px;
  }

  .action_submit_buttons {
    margin-left: 10px;
    padding: 10px;
  }

  .property-details-container {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-top: 50px;
    width: 70%;
    height: 500px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 10;
  }

  .property-image {
    width: 100%;
    height: 100%;
  }

  .property-details {
    padding: 1rem;
  }

  .property-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .property-type {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .property-price {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .property-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .location-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-location {
    font-size: 1.2rem;
  }

  .property-description {
    margin-bottom: 1rem;
  }

  .property-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .property-features li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-map-container {
    height: 300px;
    margin-bottom: 1rem;
  }
}

/* for small screens, laptops */
@media only screen and (min-width: 1024px) {
  .add_property {
    border-right: 1px solid;
    width: 60%;
  }

  .spanClass {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .property_purpose {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 30%;
    height: 60px;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 15px;
  }
  .property_purpose1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 30%;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 15px;
  }

  .property_subtype {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }
  .property_subtype1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 25px;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 470px;
    height: 60px;
    border-radius: 5px;
    /* padding-left: 10px; */
    padding-top: 20px;
    /* padding: 20px; */
    text-align: left;

    /* margin-right: 5px; */
    /* margin-bottom: 15px; */
  }

  .numberofbedroomcounter {
    background-color: white;
    color: #2e307d;
    /* border: none; */
    margin-left: 230px;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .options-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .options-right button {
    margin: 0px 10px;
    background-color: #2e307d;
    border: none;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .subtract {
    padding: 0px 10px;
  }
  .add {
    padding: 0px 8px;
  }

  .property_details {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .property_details1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    color: white;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
  .propertydetailcounter {
    background-color: inherit;
    color: inherit;
    border: none;
  }

  .property-details-container {
    position: fixed;
    top: 100px;
    right: 0;
    width: 40%;
    height: 80%;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 10;
  }

  .property-image {
    width: 100%;
    height: 100%;
  }

  .property-details {
    padding: 1rem;
  }

  .property-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .property-type {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .property-price {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .property-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .location-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-location {
    font-size: 1.2rem;
  }

  .property-description {
    margin-bottom: 1rem;
  }

  .property-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .property-features li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-map-container {
    height: 300px;
    margin-bottom: 1rem;
    /* Add your custom styling for the map component here */
  }
}

/* for desktop large screens */
@media only screen and (min-width: 1200px) {
  .spanClass {
    font-size: 24px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .add_property {
    border-right: 1px solid;
    /* margin-top: 10%; */
    width: 65%;
  }

  .property_purpose {
    background-color: #2e307d;
    border: 1px solid;
    width: 30%;
    height: 60px;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
    margin-left: 30px;
  }
  .property_purpose1 {
    background-color: #00b98e;
    border: 1px solid;
    width: 30%;
    height: 60px;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
    margin-left: 30px;
  }

  .property_subtype {
    border: 1px solid;
    background-color: #2e307d;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
  }
  .property_subtype1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: auto;
    height: 60px;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
  }

  .size_price {
    width: 70%;
  }

  .title_description {
    width: 70%;
  }

  .location {
    width: 70%;
  }

  .year_built {
    width: 70%;
  }

  .cover_image {
    width: 70%;
  }

  .floor_unit {
    width: 70%;
    margin-top: 10px;
  }

  .property_details {
    border: 3px solid;
    background-color: #2e307d;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .property_details1 {
    border: 1px solid;
    background-color: #00b98e;
    color: white;
    width: 640px;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    color: white;
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .panel {
    margin: 0px;
    padding: 0px;
  }

  .panel:focus {
    background-color: #093d76;
    color: white;
  }

  .propertybuiltyear {
    background-color: inherit;
    color: white;
    border: none;
    margin-left: 380px;
  }

  .propertydetailcounter {
    background-color: inherit;
    color: inherit;
    border: none;
  }

  .numberofbedroom {
    border: 1px solid #ced4da;
    background-color: white;
    color: #2e307d;
    width: 500px;
    height: 60px;
    border-radius: 5px;
    /* padding-top: 20px; */
    text-align: left;
  }
  .options {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
  }

  .options-right {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .options-right button {
    margin: 0px 10px;
    background-color: #2e307d;
    border: none;
    font-size: 20px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  .subtract {
    padding: 0px 10px;
  }
  .add {
    padding: 0px 8px;
  }

  .numberofbedroomcounter {
    background-color: inherit;
    color: #2e307d;
    border: none;
    margin-bottom: 8px;
    margin-left: 285px;
  }

  .numberoffloors {
    margin-left: 320px;
    font-size: 20px;
    background-color: inherit;
    color: white;
    border: none;
  }

  .numberoffloors:focus {
    background-color: inherit;
    color: inherit;
  }

  .action_buttons {
    margin-bottom: 40px;
    width: 60%;
  }

  .action_submit_buttons {
    width: 90%;
  }

  .property-details-container {
    position: fixed;
    top: 100px;
    /* adjust this value to set the margin from the top */
    right: 40px;
    width: 30%;
    height: 80%;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 30px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    z-index: 10;
    /* ensure the element is on top of other content */
  }

  .spanClass {
    font-size: 24px;
    padding-right: 30px;
    padding-left: 30px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .property-image {
    width: 100%;
    height: 100%;
  }

  .property-details {
    /* width: 50%; */
    padding: 1rem;
    /* background-color: #f2f2f2; */
  }

  .property-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .property-type {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .property-price {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .property-location-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .location-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-location {
    font-size: 1.2rem;
  }

  .property-description {
    margin-bottom: 1rem;
  }

  .property-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .property-features li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .feature-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .property-map-container {
    height: 300px;
    margin-bottom: 1rem;
  }
}
