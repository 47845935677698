.spinner-container {
  display: none; /* Hide the container by default */
  z-index: 9999; /* Ensure it's above other content */
}

/* Apply blur effect to the overlay */
.spinner-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Change the color and opacity as needed */
  backdrop-filter: blur(8px); /* Adjust the blur radius as needed */
}

.spinner {
  position: relative;
}

/* Center the spinner */
.spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search-main {
  /* margin-top: 10px; */
  padding: 0px 20px;
  background-color: #00b98e;
}
.search-second {
  padding: 30px;
}
.contact-agent {
  margin-left: -40px;
}
.property-listing-title {
  display: flex;
  justify-content: space-between;
  padding: 60px;
}
@media (max-width: 480px) {
  .property-listing-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .contact-agent {
    margin-left: 0px;
  }
  .read-more {
    margin-left: -40px;
  }
}
